import { useAdjustLogger } from "@/utils/adjust"
import { appStoreConstant } from "@/utils/branch"
import {
	Desktop,
	Mobile,
	Tablet,
} from "@/utils/breakpoints"
import { assetUrl } from "@/utils/cdn"
import { NotificationContext } from "@/utils/notification"
import axios from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useContext, useEffect, useState } from "react"
import { useDropzone } from "react-dropzone"
import { useInView } from "react-intersection-observer"
import { animated } from "react-spring"
import { FlexBox } from "react-styled-flex"
import {
	useEditorParamsContext,
	useUploadOption,
} from "sections/editor/editor"
import styled from "styled-components"
import { z } from "zod"
import { useBoop } from "../animate/display-trigger"
import { AnimatedWords } from "../animated-words"
import Bubble from "../bubble"
import Button, { ConditionalAnchor } from "../button"
import Floating, { Relative } from "../floating"
import {
	AppStoreIcon,
	DesktopIcon,
	GooglePlayIcon,
} from "../home/cover/icons"
import QR from "../home/cover/qr"
import {
	CustomThemedResource,
	ResponsiveImage,
} from "../image"
import { ErrorMessage } from "../message"
import {
	getFormatsFromMimeTypes,
	SimpleDropArea,
} from "../simple-drop-area"
import { GetHeader as GetAnimatedHeader } from "./headline-alternative"

const Separator = styled.div`
	flex: 1;
	height: 1px;

	background-color: var(--color-separator);

	margin: auto 0;
`

const StoresContainer = styled.div`
	display: flex;
	flex-direction: row;
`

const featureAiToolSchema = z.enum([
	"deform",
	"ai_photo",
	"ai_restyle",
	"text_to_image",
	"text_to_video",
	"ai_music_generator",
])

const tagSchema = z.enum(["h1", "h2", "h3", "h4"])
type TagsType = z.infer<typeof tagSchema>

const titleAttrSchema = z
	.array(
		z.object({
			start: z.number(),
			length: z.number(),
			color: z.string().optional(),
			dark_color: z.string().optional(),
		}),
	)
	.optional()

export const headlineSchema = z.object({
	component: z.literal("headline"),
	bubble_text: z.string().optional(),
	title: z.string(),
	subtitle: z.string(),
	button_text: z.string().optional(),
	button_link: z.string().optional(),
	media: z.string(),
	without_background: z.boolean().optional(),
	image_left: z.boolean().optional(),
	without_qr: z.boolean().optional(),
	upload: z.boolean().optional(),
	tool: featureAiToolSchema.optional(),
	tag: tagSchema.optional().default("h1"),
	title_attr: titleAttrSchema,
	left_icon: z.string().optional(),
	right_icon: z.string().optional(),
	className: z.string().optional(),
	video_thumb: z.string().optional(),
	words: z
		.object({
			name: z.string(),
			lightColor: z.string(),
			darkColor: z.string(),
		})
		.array()
		.optional(),
	style: z.literal("large").optional(),
	platforms: z
		.array(
			z.union([
				z.literal("ios"),
				z.literal("android"),
				z.literal("web"),
			]),
		)
		.optional(),
	logEvent: z
		.union([
			z.literal("header"),
			z.literal("url_to_video"),
			z.literal("script_to_video"),
			z.literal("ai_avatars"),
		])
		.optional(),
})

type HeadlineProps = z.infer<typeof headlineSchema>

export default function HeadlineCover(
	props: HeadlineProps,
) {
	const [text, setText] = useState("")
	const editor = useEditorParamsContext()
	const {
		bubble_text,
		title,
		subtitle,
		button_text,
		button_link,
		media,
		without_background,
		without_qr,
		image_left,
		upload,
		tool,
		platforms,
		tag,
		style: componentStyle,
	} = props

	const acceptedPlatforms = platforms ?? [
		"ios",
		"android",
		"web",
	]

	const [loading, setLoading] = useState(false)

	const { t } = useTranslation()

	const { notify } = useContext(NotificationContext)

	const {
		onDrop,
		onClick: toolClick,
		formats,
	} = useUploadOption(upload, tool)

	const dropzoneState = useDropzone({
		accept: getFormatsFromMimeTypes(
			formats ?? ["image/png"],
		),
		onDrop,
		maxFiles: 1,
		maxSize: 10_000_000,
		noClick: true,
	})

	async function getMagic() {
		setLoading(true)
		try {
			const magicText = await axios
				.post<string>("/api/deforum-magic-prompt", {
					tool: tool,
				})
				.then((res) => res.data)
			setLoading(false)
			updateInput(magicText)
		} catch (error) {
			console.error(error)
			setLoading(false)
			notify(
				<ErrorMessage>
					{t("txt_could_not_get_magic")}
				</ErrorMessage>,
			)
		}
	}

	const [inputHistory, _] = useState<string[]>([])
	const maxHistoryLength = 50

	useEffect(() => {
		const handleUndo = (e: KeyboardEvent) => {
			if (
				(e.ctrlKey && e.key === "z") ||
				(e.metaKey && e.key === "z")
			) {
				if (inputHistory.length > 0) {
					setText && setText(inputHistory.pop() ?? "")
				}
			}
		}

		window.addEventListener("keydown", handleUndo)

		return () => {
			window.removeEventListener("keydown", handleUndo)
		}
	}, [text, inputHistory, setText])

	function updateInput(newValue: string) {
		inputHistory.push(text ?? "")

		if (inputHistory.length >= maxHistoryLength) {
			inputHistory.shift()
		}
		setText && setText(newValue)
	}

	if (componentStyle && componentStyle === "large") {
		return <HeadlineLarge {...props} />
	}

	return (
		<>
			<div className="relative flex w-full justify-center pt-[16px] tablet:py-[40px] desktop:py-[55px]">
				{!without_background && (
					<div className="pointer-events-none absolute right-0 top-0 w-full -translate-y-1/4 overflow-hidden">
						<ResponsiveImage
							src="/feature/intro"
							className="w-full object-cover"
						/>
					</div>
				)}
				<div
					className={clsx(
						"flex justify-center tablet:w-[720px] tablet:items-center tablet:justify-between desktop:w-[950px]",
						image_left
							? "tablet:flex-row-reverse"
							: "tablet:flex-row",
					)}>
					<div className="flex w-[90%] flex-col items-center gap-2 tablet:max-w-[370px] tablet:items-start tablet:gap-3 desktop:max-w-[517px]">
						{bubble_text && (
							<div className="z-[1] flex flex-row justify-center">
								<Bubble
									text={bubble_text}
									color="pinkPurpleLight"
								/>
							</div>
						)}
						<GetHeader tag={tag} title={title} />
						<video
							autoPlay
							playsInline
							loop
							muted
							src={media + "#t=0.001"}
							className="z-[1] h-[330px] rounded-[8px] object-cover tablet:hidden"
						/>
						<p className="text-body-2 z-[1] mt-2 tablet:mt-0">
							{subtitle}
						</p>

						{upload && tool !== "ai_music_generator" && (
							<ConditionalAnchor
								href={appStoreConstant}
								className="mt-2 block w-full desktop:hidden">
								{button_text}
							</ConditionalAnchor>
						)}

						{/* DropArea */}
						{upload &&
							dropzoneState &&
							toolClick &&
							formats && (
								<SimpleDropArea
									border
									className="mt-5 hidden h-[230px] w-[475px] bg-blue-50 desktop:flex"
									state={dropzoneState}
									formats={formats}
									openExplorer={toolClick}
									loading={editor.loading}
								/>
							)}

						{/* TextArea */}
						{upload &&
							toolClick &&
							button_text &&
							tool !== "ai_music_generator" &&
							!onDrop && (
								<>
									<div className="relative z-20 hidden h-[130px] w-full rounded-[14px] border border-color-separator bg-color-cell desktop:flex">
										<textarea
											disabled={editor.loading || loading}
											placeholder={t(
												"txt_describe_imagination",
											)}
											className={clsx(
												"text-body-5 no-scrollbar h-[95px] w-full resize-none rounded-[14px] bg-color-cell py-2 pl-3 pr-8 placeholder:text-color-placeholder focus-visible:outline-none",
												loading
													? "text-color-placeholder"
													: "text-blue-800",
											)}
											value={text}
											onChange={(e) => {
												const inputValue =
													e.currentTarget.value
												if (inputValue.length <= 1000) {
													setText(inputValue)
												}
											}}
										/>
										<button
											disabled={loading}
											onClick={() => getMagic()}
											className="absolute bottom-2 left-3 flex flex-row items-center gap-1 disabled:pointer-events-none">
											<img
												src={assetUrl(
													"/ai-tools/text-to-image/magic.svg",
												)}
												alt="magic"
											/>
											<p className="text-body-5 font-500 text-primary-500">
												Surprise Me
											</p>
											<img
												alt="loading"
												src={assetUrl(
													"/ai-tools/text-to-image/loading.webp",
												)}
												className={clsx(
													"pointer-events-none h-3 w-3 animate-spin [animation-timing-function:linear]",
													loading
														? "opacity-100"
														: "opacity-0",
												)}
											/>
										</button>
										<button
											onClick={() => {
												setText("")
											}}
											className={clsx(
												"absolute right-3 top-2 flex h-[17px] w-[17px] items-center justify-center rounded-full",
												"bg-blue-200 transition-all hover:bg-blue-400",
												text.length === 0
													? "pointer-events-none opacity-0"
													: "opacity-100",
											)}>
											<CustomThemedResource
												format="svg"
												source="/general/close"
												className="h-[12px] w-[12px]"
											/>
										</button>
										<div className="absolute bottom-2 right-3 flex flex-row items-center gap-2">
											<p className="text-[14px] font-500 text-color-placeholder">
												{text.length + "/1000"}
											</p>
										</div>
									</div>
									<Button
										primary
										text={button_text}
										onClick={() => toolClick(text)}
										className="hidden w-full desktop:block"></Button>
								</>
							)}
						{button_text &&
							tool === "ai_music_generator" && (
								<Button
									loading={editor.loading}
									primary
									onClick={toolClick}
									text={button_text}
									className="z-[1] mt-2 tablet:mt-4 desktop:mt-9 desktop:w-[275px]"
								/>
							)}
						{button_text && !upload && (
							<Button
								primary
								full
								text={button_text}
								href={button_link}
								className="z-[1] mt-2 tablet:mt-4 desktop:mt-9 desktop:w-[275px]"
							/>
						)}
						{!without_qr && (
							<PlatformsSection
								platforms={acceptedPlatforms}
							/>
						)}
					</div>
					<video
						playsInline
						autoPlay
						loop
						muted
						src={media + "#t=0.001"}
						className="z-[1] hidden h-[367px] w-[293px] rounded-[14px] object-cover tablet:block desktop:h-[489px] desktop:w-[377px]"
					/>
				</div>
			</div>
		</>
	)
}

function GetHeader(props: {
	tag: TagsType
	title: string
}) {
	const { tag, title } = props

	switch (tag) {
		case "h1":
			return (
				<h1 className="text-h3 z-[1] text-center tablet:text-start">
					{title}
				</h1>
			)
		case "h2":
			return (
				<h2 className="text-h3 z-[1] text-center tablet:text-start">
					{title}
				</h2>
			)
		case "h3":
			return (
				<h3 className="text-h3 z-[1] text-center tablet:text-start">
					{title}
				</h3>
			)
		case "h4":
			return (
				<h4 className="text-h3 z-[1] text-center tablet:text-start">
					{title}
				</h4>
			)
	}
}

function HeadlineLarge(props: HeadlineProps) {
	const {
		image_left,
		bubble_text,
		button_link,
		button_text,
		tag,
		title,
		subtitle,
		media,
		without_qr,
		platforms,
		words,
		title_attr,
		left_icon,
		right_icon,
		className,
		video_thumb,
		logEvent,
	} = props

	const logAdjust = useAdjustLogger()

	const acceptedPlatforms = platforms ?? [
		"ios",
		"android",
		"web",
	]

	const [showImage, setShowImage] = useState(true)

	useEffect(() => {
		const isIOS = /iPad|iPhone|iPod/.test(
			navigator.userAgent,
		)
		const isSafari = /^((?!chrome|android).)*safari/i.test(
			navigator.userAgent,
		)

		const isWebm = media.endsWith(".webm")

		setShowImage(
			Boolean(isWebm && video_thumb && (isIOS || isSafari)),
		)
	}, [media, showImage, video_thumb])

	return (
		<section className="flex w-full items-center justify-center px-4 tablet:px-0 desktop:px-[calc(50vw-702px)]">
			<div
				className={clsx(
					"relative flex w-full flex-col items-center gap-6 tablet:w-[720px] tablet:items-center tablet:justify-between desktop:w-full desktop:px-[30px]",
					image_left
						? "tablet:flex-row-reverse"
						: "tablet:flex-row",
					className && !showImage
						? className
						: "py-[16px] tablet:py-[40px] desktop:py-[60px]",
				)}>
				<div className="flex w-full flex-col items-center gap-2 tablet:w-auto tablet:max-w-[295px] tablet:items-start desktop:max-w-[600px]">
					{bubble_text && (
						<div className="z-[1] flex flex-row justify-center">
							<Bubble
								text={bubble_text}
								color="pinkPurpleLight"
							/>
						</div>
					)}
					<GetAnimatedHeader
						tag={tag}
						title={title}
						title_attr={title_attr}
						textStart>
						{words && <AnimatedWords words={words} />}
					</GetAnimatedHeader>
					{/* <TitleWithTag tag={tag} title={title} /> */}
					{!showImage && (
						<video
							autoPlay
							playsInline
							loop
							muted
							src={media + "#t=0.001"}
							className="z-[1] my-2 max-h-[442px] min-h-[300px] w-full rounded-[12px] object-contain tablet:hidden"
						/>
					)}

					{showImage && (
						<img
							className="z-[1] my-2 max-h-[442px] min-h-[300px] w-full object-contain px-5 tablet:hidden"
							src={video_thumb}
						/>
					)}
					<p className="font z-[1] font-400 leading-[1.618] text-blue-700 tablet:mt-[20px] tablet:text-[16px] desktop:text-[18px]">
						{subtitle}
					</p>
					{button_text && button_link && (
						<a
							href={button_link}
							onClick={() => {
								if (logEvent) {
									logAdjust?.logEventWithPayload(
										"w_dp_start_now",
										{ from: "home", type: logEvent },
									)
								}
							}}
							className={clsx(
								"z-[1] mt-2 flex w-full shrink-0 select-none flex-row items-center justify-center gap-2 text-[18px] font-600",
								"rounded-[6px] tablet:mt-4 desktop:mt-5 desktop:h-[64px] desktop:w-[280px] desktop:rounded-[10px]",
								"bg-primary-500 text-color-white transition-colors duration-300 ease-in-out hover:bg-primary-600",
								"cursor-pointer disabled:cursor-not-allowed disabled:opacity-50",
								"px-[34px] py-[14px]",
							)}>
							{left_icon && <img src={left_icon} />}
							{button_text}
							{right_icon && <img src={right_icon} />}
						</a>
					)}

					{!without_qr && (
						<PlatformsSection
							platforms={acceptedPlatforms}
						/>
					)}
				</div>
				{!showImage && (
					<video
						playsInline
						autoPlay
						loop
						muted
						src={media + "#t=0.001"}
						className={clsx(
							"z-[1] hidden max-h-[500px] min-h-[300px] w-[355px] rounded-[12px] object-contain tablet:block desktop:max-h-[700px] desktop:min-h-[510px] desktop:w-[45%]",
						)}
					/>
				)}

				{showImage && (
					<img
						className="z-[1] hidden max-h-[500px] min-h-[300px] w-[355px] object-contain tablet:block desktop:max-h-[700px] desktop:min-h-[510px] desktop:w-[45%]"
						src={video_thumb}
					/>
				)}
			</div>
		</section>
	)
}

type Platforms = "web" | "android" | "ios"

interface PlatformsSectionProps {
	platforms: Platforms[]
}

export function PlatformsSection(
	props: PlatformsSectionProps,
) {
	const { platforms: acceptedPlatforms } = props

	const { inView } = useInView()
	const [storeSelected, setStoreSelected] = useState(false)
	const [qrSelected, setQrSelected] = useState(false)
	const [hidden, setHidden] = useState(true)

	if (hidden && (storeSelected || qrSelected))
		setHidden(false)

	const style = useBoop({
		scale: 1.3,
		trigger: storeSelected || qrSelected,
		close: () => {
			setHidden(true)
		},
	})

	return (
		<>
			<Desktop className="flex flex-row gap-2">
				<Relative>
					<FlexBox alignItems="stretch" margin="24px -8px">
						<FlexBox
							onMouseOver={() => setStoreSelected(true)}
							onMouseLeave={() => setStoreSelected(false)}>
							{acceptedPlatforms.map((platform) => {
								switch (platform) {
									case "android":
										return <GooglePlayIcon key={platform} />
									case "ios":
										return <AppStoreIcon key={platform} />
									case "web":
										return
								}
							})}
						</FlexBox>
						<Floating
							position={{
								top: inView ? -265 : 110,
								left: 0,
							}}
							z={13}
							none={hidden}>
							<FlexBox center width="100%">
								<animated.div
									style={style}
									onMouseOver={() =>
										storeSelected && setQrSelected(true)
									}
									onMouseLeave={() => setQrSelected(false)}>
									<QR />
								</animated.div>
							</FlexBox>
						</Floating>
					</FlexBox>
				</Relative>
				{acceptedPlatforms
					.filter((platform) => {
						return platform === "web"
					})
					.map((platform) => (
						<div key={platform} className=" my-6">
							<DesktopIcon />
						</div>
					))}
			</Desktop>

			<Tablet>
				<FlexBox alignItems="stretch" margin="16px -8px">
					<StoresContainer>
						{acceptedPlatforms.map((platform) => {
							switch (platform) {
								case "android":
									return <GooglePlayIcon key={platform} />
								case "ios":
									return <AppStoreIcon key={platform} />
								case "web":
									return
							}
						})}
					</StoresContainer>
				</FlexBox>
			</Tablet>

			<Mobile>
				<FlexBox
					maxWidth="1175px"
					width="var(--w-screen)"
					alignItems="stretch"
					margin="12px 0px">
					<Separator />
					<StoresContainer>
						{acceptedPlatforms.map((platform) => {
							switch (platform) {
								case "android":
									return <GooglePlayIcon key={platform} />
								case "ios":
									return <AppStoreIcon key={platform} />
								case "web":
									return
							}
						})}
					</StoresContainer>
					<Separator />
				</FlexBox>
			</Mobile>
		</>
	)
}
