export function isStringUrl(str: string) {
	if (str.startsWith("http")) {
		return {
			website_url: str,
			initial_message: null,
		}
	}

	return {
		website_url: null,
		initial_message: str,
	}
}
