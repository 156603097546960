import clsx from "clsx"
import { useEffect, useState } from "react"

interface AnimatedWordsProps {
  words: {
    name: string
    lightColor: string
    darkColor: string
  }[]
}

export function AnimatedWords(props: AnimatedWordsProps) {
  const { words } = props
  const [currentNameIndex, setCurrentNameIndex] =
    useState(0)
  const [animationState, setAnimationState] = useState<
    "typing" | "deleting"
  >("typing")
  const [typedCharacters, setTypedCharacters] =
    useState<string>("")

  useEffect(() => {
    if (words.length === 1) {
      setTypedCharacters(
        words[0].name.slice(0, typedCharacters.length + 1),
      )
      return
    }

    const typingTimer = setTimeout(
      () => {
        if (animationState === "typing") {
          if (
            typedCharacters.length <
            words[currentNameIndex].name.length
          ) {
            setTypedCharacters(
              words[currentNameIndex].name.slice(
                0,
                typedCharacters.length + 1,
              ),
            )
          } else {
            setTimeout(() => {
              setAnimationState("deleting")
            }, 800)
          }
        } else {
          if (typedCharacters.length > 0) {
            setTypedCharacters(
              typedCharacters.slice(
                0,
                typedCharacters.length - 1,
              ),
            )
          } else {
            setAnimationState("typing")
            setCurrentNameIndex(
              (prevIndex) => (prevIndex + 1) % words.length,
            )
          }
        }
      },
      animationState === "typing" ? 150 : 90,
    )

    return () => clearTimeout(typingTimer)
  }, [
    typedCharacters,
    animationState,
    words,
    currentNameIndex,
  ])

  return (
    <>
      <span
        className={clsx(
          animationState === "typing"
            ? "animate-typing"
            : "animate-deleting",
          "inline-flex min-w-[200px] text-start text-[24px] font-800 tablet:text-[32px] desktop:text-[64px]",
          "overflow-hidden whitespace-nowrap dark:hidden",
        )}
        style={{
          color: words[currentNameIndex].lightColor,
        }}>
        {typedCharacters}
      </span>
      <span
        className={clsx(
          animationState === "typing"
            ? "animate-typing"
            : "animate-deleting",
          "min-w-[200px] text-start text-[24px] font-800 dark:inline-flex tablet:text-[32px] desktop:text-[64px]",
          "hidden overflow-hidden whitespace-nowrap",
        )}
        style={{
          color: words[currentNameIndex].darkColor,
        }}>
        {typedCharacters}
      </span>
    </>
  )
}
