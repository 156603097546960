import clsx from "clsx"
import { CSSProperties, useState } from "react"
import { z } from "zod"

export const questionSchema = z.object({
	question: z.string(),
	answer: z.string(),
})

export const questionComponent = z.object({
	component: z.literal("faq"),
	questions: z.array(questionSchema),
	style: z.literal("large").optional(),
})

type FAQProps = z.infer<typeof questionComponent>

export function calculateDynamicHeight(
	text: string,
	width: string,
	index: number,
	questionIndex: number,
): string {
	if (questionIndex === index) {
		const div = document.createElement("div")
		div.style.whiteSpace = "pre-line"
		div.style.position = "absolute"
		div.style.visibility = "hidden"
		div.style.width = width
		div.style.padding = "20px"
		div.style.fontSize = "16px"
		div.style.fontWeight = "400"
		div.textContent = text

		document.body.appendChild(div)

		const height = div.getBoundingClientRect().height

		document.body.removeChild(div)

		return `${height}px`
	} else {
		return "0px"
	}
}

export default function FAQ(props: FAQProps) {
	const { questions, style } = props
	const [questionIndex, setQuestionIndex] = useState(-1)

	if (style && style === "large") {
		return <FAQLarge {...props} />
	}

	return (
		<>
			<div className="flex w-full justify-center py-[35px] tablet:py-[40px] desktop:py-[55px]">
				<div className="flex w-[90%] flex-col items-center gap-5 tablet:w-[604px] desktop:w-[775px]">
					<h2 className="text-h5">
						Frequently Asked Questions
					</h2>
					<div className="flex w-full flex-col items-center gap-4">
						{questions.map((question, index) => (
							<div
								key={index}
								className={clsx(
									"flex w-full flex-col overflow-hidden rounded-[10px] bg-color-background",
								)}>
								<div
									onClick={() => {
										if (questionIndex === index)
											setQuestionIndex(-1)
										else setQuestionIndex(index)
									}}
									className={clsx(
										"flex cursor-pointer select-none flex-row items-center justify-between p-4",
										questionIndex === index &&
											"border-b border-color-separator",
									)}>
									<p
										className={clsx(
											"text-body-3 max-w-[95%] transition-colors duration-300",
											questionIndex === index &&
												"text-primary-500",
										)}>
										{question.question}
									</p>
									{questionIndex !== index ? (
										<>
											<PlusLight />
										</>
									) : (
										<div className="flex flex-1 flex-row justify-end">
											<Minus />
										</div>
									)}
								</div>
								<div
									style={
										{
											whiteSpace: "pre-line",
											"--desktop-size":
												calculateDynamicHeight(
													question.answer,
													"735px",
													index,
													questionIndex,
												),
											"--tablet-size":
												calculateDynamicHeight(
													question.answer,
													"604px",
													index,
													questionIndex,
												),
											"--mobile-size":
												calculateDynamicHeight(
													question.answer,
													"90%",
													index,
													questionIndex,
												),
										} as CSSProperties
									}
									className={clsx(
										"max-h-[var(--mobile-size)] transition-all duration-300 tablet:max-h-[var(--tablet-size)] desktop:max-h-[var(--desktop-size)]",
									)}>
									<p className="text-body-5 p-5">
										{question.answer}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	)
}

function FAQLarge(props: FAQProps) {
	const { questions } = props
	const [questionIndex, setQuestionIndex] = useState(-1)

	return (
		<>
			<div className="z-10 flex w-full justify-center py-[35px] tablet:py-[40px] desktop:px-[calc(50vw-702px)] desktop:py-[55px]">
				<div className="flex w-[90%] flex-col items-center gap-5 tablet:w-[604px] desktop:w-full desktop:flex-row desktop:items-start desktop:justify-between desktop:px-[30px]">
					<h2 className="text-center text-[26px] font-800 text-blue-900 tablet:text-[34px] tablet:font-800 desktop:max-w-[270px] desktop:text-start desktop:text-[52px] desktop:leading-[1.15]">
						Frequently Asked Questions
					</h2>
					<div className="flex w-full flex-col items-center gap-4 desktop:w-[888px]">
						{questions.map((question, index) => (
							<div
								key={index}
								className={clsx(
									"flex w-full flex-col overflow-hidden rounded-[10px] bg-blue-100",
								)}>
								<div
									onClick={() => {
										if (questionIndex === index)
											setQuestionIndex(-1)
										else setQuestionIndex(index)
									}}
									className={clsx(
										"flex cursor-pointer select-none flex-row items-center justify-between p-4",
										questionIndex === index &&
											"border-b border-color-separator",
									)}>
									<p
										className={clsx(
											"text-body-3 max-w-[95%] transition-colors duration-300",
											questionIndex === index &&
												"text-primary-500",
										)}>
										{question.question}
									</p>
									{questionIndex !== index ? (
										<>
											<PlusLight />
										</>
									) : (
										<div className="flex flex-1 flex-row justify-end">
											<Minus />
										</div>
									)}
								</div>
								<div
									style={
										{
											whiteSpace: "pre-line",
											"--desktop-size":
												calculateDynamicHeight(
													question.answer,
													"888px",
													index,
													questionIndex,
												),
											"--tablet-size":
												calculateDynamicHeight(
													question.answer,
													"604px",
													index,
													questionIndex,
												),
											"--mobile-size":
												calculateDynamicHeight(
													question.answer,
													"90%",
													index,
													questionIndex,
												),
										} as CSSProperties
									}
									className={clsx(
										"max-h-[var(--mobile-size)] transition-all duration-300 tablet:max-h-[var(--tablet-size)] desktop:max-h-[var(--desktop-size)]",
									)}>
									<p className="text-body-5 p-5">
										{question.answer}
									</p>
								</div>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	)
}

const PlusLight = () => (
	<svg
		width="20"
		height="20"
		viewBox="0 0 20 20"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.33337 9.99992H16.6667M10 3.33325L10 16.6666"
			stroke="var(--color-blue-900)"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)

export const Minus = () => (
	<svg
		width="20"
		height="21"
		viewBox="0 0 20 21"
		fill="none"
		xmlns="http://www.w3.org/2000/svg">
		<path
			d="M3.33398 10.8655H16.6673"
			stroke="var(--color-primary-500)"
			strokeWidth="2"
			strokeLinecap="round"
		/>
	</svg>
)
