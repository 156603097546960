import styles from "@/styles/home/cover.module.css"
import {
  appStoreConstant,
  playStoreConstant,
} from "@/utils/branch"
import { assetUrl } from "@/utils/cdn"

const AppStoreIcon = () => {
  return (
    <a
      href={appStoreConstant}
      target="_blank"
      rel="noopener noreferrer">
      <img
        alt="app store icon light"
        data-hide-on-theme="dark"
        src={assetUrl(
          "/icons/home/cover/app-store-light.svg"
        )}
        className={styles["stores"]}
      />
      <img
        alt="app store icon dark"
        data-hide-on-theme="light"
        src={assetUrl(
          "/icons/home/cover/app-store-dark.svg"
        )}
        className={styles["stores"]}
      />
    </a>
  )
}

const GooglePlayIcon = () => {
  return (
    <a
      href={playStoreConstant}
      target="_blank"
      rel="noopener noreferrer">
      <img
        alt="google play icon light"
        data-hide-on-theme="dark"
        src={assetUrl(
          "/icons/home/cover/google-play-light.svg"
        )}
        className={styles["stores"]}
      />
      <img
        alt="google play icon dark"
        data-hide-on-theme="light"
        src={assetUrl(
          "/icons/home/cover/google-play-dark.svg"
        )}
        className={styles["stores"]}
      />
    </a>
  )
}

const DesktopIcon = () => {
  return (
    <>
      <img
        alt="app store icon light"
        data-hide-on-theme="dark"
        src={assetUrl(
          "/icons/home/cover/web-icon-light.svg"
        )}
        className="m-[7px] rounded-[6px] bg-color-cell"
      />
      <img
        alt="app store icon dark"
        data-hide-on-theme="light"
        src={assetUrl(
          "/icons/home/cover/web-icon-dark.svg"
        )}
        className="m-[7px] rounded-[6px] bg-color-cell"
      />
    </>
  )
}

export { AppStoreIcon, GooglePlayIcon, DesktopIcon }
