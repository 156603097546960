import { TFunction } from "next-i18next"

export type Validator = (
  value: string,
  t: TFunction
) => string | undefined
export const notEmpty: Validator = (
  value,
  t
): string | undefined => {
  if (value.length === 0) return t("txt_error_not_empty")
}

export const isEmail: Validator = (
  value,
  t
): string | undefined => {
  const atIndex = value.indexOf("@")
  const dotIndex = value.lastIndexOf(".")
  const atSymbols = value.split("@")

  if (atIndex === 0)
    return t("txt_error_cannot_start_with_at")
  if (atIndex === -1)
    return t("txt_error_should_contain_at")
  if (
    dotIndex === -1 ||
    dotIndex < atIndex ||
    dotIndex === value.length - 1
  )
    return t("txt_error_email_wrong_domain")
  if (atSymbols.length > 2)
    return t("txt_error_email_one_at")
}

export const isPhone: Validator = (value, t) => {
  const plusIndex = value.lastIndexOf("+")
  if (plusIndex !== 0 && plusIndex !== -1)
    return t("txt_error_plus_middle")
  const [_, ...numbers] = value.split("")
  if (numbers.join("").match(/[^0-9\s]/) !== null)
    return t("txt_error_only_numbers_and_plus")
  if (value.length <= 6) return t("txt_error_short_phone")
  if (value[0] !== "0" && value[0] !== "+")
    return t("txt_error_phone_format")
}

export const isPhoneOrEmpty: Validator = (value, t) => {
  if (!value) {
    return undefined
  }

  return isPhone(value, t)
}
