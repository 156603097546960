import { assetUrl } from "@/utils/cdn"
import useAuth from "@/utils/client-auth"
import { isStringUrl } from "@/utils/functions"
import { NotificationContext } from "@/utils/notification"
import { useShortCut } from "@kartyom/shared-components"
import { isEmail, notEmpty } from "@/utils/validators"
import axios, { AxiosError } from "axios"
import clsx from "clsx"
import { useTranslation } from "next-i18next"
import { useTheme } from "next-themes"
import { useRouter } from "next/router"
import { WaitlistSubmitResponse } from "pages/api/tool-waitlist-submit"
import { StartSessionResponse } from "pages/api/wizard-start-session"
import { FormEvent, useContext, useState } from "react"
import { z } from "zod"
import { AnimatedWords } from "../animated-words"
import Bubble from "../bubble"
import { useValidator } from "../input"
import { ErrorMessage, SuccessMessage } from "../message"
import { SubscriptionPopup } from "../subscription-popup"

const tagSchema = z.enum(["h1", "h2", "h3", "h4"])
type TagsType = z.infer<typeof tagSchema>

const titleAttrSchema = z
	.array(
		z.object({
			start: z.number(),
			length: z.number(),
			color: z.string().optional(),
			dark_color: z.string().optional(),
		}),
	)
	.optional()

type TitleAttr = z.infer<typeof titleAttrSchema>

export const headlineAlternativeSchema = z.object({
	component: z.literal("headline_alternative"),
	title: z.string(),
	subtitle: z.string().optional(),
	button_text: z.string().optional(),
	placeholder: z.string().optional(),
	isWaitlist: z.boolean().default(false),
	inputIcon: z.string().nullable().default(null),
	tag: tagSchema.optional().default("h1"),
	bubble_text: z.string().optional(),
	title_attr: titleAttrSchema,
	inputType: z
		.union([z.literal("url"), z.literal("text")])
		.default("url"),
	words: z
		.object({
			name: z.string(),
			lightColor: z.string(),
			darkColor: z.string(),
		})
		.array()
		.optional(),
})

type HeadlineAlternativeProps = z.infer<
	typeof headlineAlternativeSchema
>

export function HeadlineAlternative(
	props: HeadlineAlternativeProps,
) {
	const {
		title,
		subtitle,
		button_text,
		bubble_text,
		placeholder,
		isWaitlist,
		inputIcon,
		words,
		title_attr,
		inputType,
		tag,
	} = props

	const [subscriptionPopupOpen, setSubscriptionPopupOpen] =
		useState(false)

	const [text, setText] = useState("")
	const router = useRouter()
	const { userInfo } = useAuth()
	const { notify } = useContext(NotificationContext)
	const { t } = useTranslation()
	const { validate: emailValidate, errors: emailErrors } =
		useValidator(text, [notEmpty, isEmail])
	const [isLoading, setIsLoading] = useState(false)

	async function handleShareLink(message: string) {
		setIsLoading(true)
		if (userInfo.isAnonymous) {
			router.push(
				`/login?redirect_url=/chat?initial_message=${message}`,
			)
			setIsLoading(false)
			return
		}

		try {
			const context = isStringUrl(message)

			await axios
				.post<StartSessionResponse>(
					"/api/wizard-start-session",
					{
						context,
						group: null,
						tag: null,
					},
				)
				.then((r) => {
					router.push(`/chat?session_id=${r.data.id}`)
				})
			setText("")
		} catch (e) {
			console.error(e)

			if (e instanceof AxiosError) {
				if (
					e.response?.status === 431 ||
					e.response?.status === 432
				) {
					setSubscriptionPopupOpen(true)
				} else {
					notify(
						<ErrorMessage>
							{t("txt_something_wrong")}
						</ErrorMessage>,
					)
				}
			} else {
				notify(
					<ErrorMessage>
						{t("txt_something_wrong")}
					</ErrorMessage>,
				)
			}
		}
		setIsLoading(false)
	}

	async function handleWaitlistSubmit() {
		setIsLoading(true)
		const from = String(
			router.query.slug || router.query.feature,
		)
		await axios
			.post<WaitlistSubmitResponse>(
				"/api/tool-waitlist-submit",
				{
					from,
					email: text,
				},
			)
			.then(() => {
				notify(
					<SuccessMessage title={t("txt_waitlist_title")}>
						{t("txt_waitlist_desc")}
					</SuccessMessage>,
				)
			})
			.catch(() => {
				notify(
					<ErrorMessage>
						{t("txt_something_wrong")}
					</ErrorMessage>,
				)
			})
		setText("")
		setIsLoading(false)
	}

	useShortCut({
		shortCut: "Enter",
		handler: () => {
			if (
				text.trim().length === 0 ||
				isLoading ||
				!isUrl(text)
			) {
				return
			}

			if (isWaitlist) {
				handleWaitlistSubmit()
			} else {
				handleShareLink(text)
			}
		},
	})

	async function handleSubmit(
		e: FormEvent<HTMLFormElement>,
	) {
		e.preventDefault()
		if (
			isWaitlist
				? emailErrors !== ""
				: text.trim().length === 0 || !isUrl(text)
		)
			return

		if (isWaitlist) {
			await handleWaitlistSubmit()
		} else {
			await handleShareLink(text)
		}
	}

	function isUrl(str: string) {
		if (inputType !== "url") return true

		try {
			new URL(str)
			return true
		} catch (e) {
			return false
		}
	}

	return (
		<>
			<section
				className={clsx(
					"flex flex-col items-center justify-center",
					"pb-5a pt-[35px] tablet:pt-[90px] desktop:pt-[150px]",
					"relative z-10 tablet:pb-[40px] desktop:pb-[80px]",
				)}>
				<img
					src={assetUrl(
						"/comps/headline-alternative-bg.webp",
					)}
					alt="bg-picture"
					className="absolute left-0 top-0 -z-10 h-[330px] w-full dark:opacity-40 tablet:h-auto tablet:max-h-[600px]"
				/>

				<div
					className={clsx(
						"mb-[32px] flex flex-col gap-[22px] tablet:w-[579px]",
						"px-[16px] desktop:w-[696px]",
					)}>
					{bubble_text && (
						<div className="z-[1] flex flex-row justify-center">
							<Bubble
								text={bubble_text}
								color="pinkPurpleLight"
							/>
						</div>
					)}
					<GetHeader
						tag={tag}
						title={title}
						title_attr={title_attr}>
						{words && <AnimatedWords words={words} />}
					</GetHeader>
					<span className="text-body-2 max-w-[800px] text-center">
						{subtitle}
					</span>
				</div>

				<form
					onSubmit={handleSubmit}
					className={clsx(
						"flex rounded-[16px] bg-color-cell px-[12px] py-[12px]",
						"mx-[16px] w-full max-w-[343px] items-center gap-[16px]",
						"relative tablet:max-w-[718px] desktop:max-w-[774px]",
					)}
					style={{
						boxShadow:
							"0px 7px 24px -10px rgba(111, 115, 212, 0.25)",
					}}>
					<input
						onChange={(e) => setText(e.currentTarget.value)}
						type={inputType}
						onBlur={() => {
							if (!isWaitlist) return

							emailValidate()
						}}
						value={text}
						placeholder={placeholder}
						className={clsx(
							inputIcon && "pl-[35px]",
							"w-full border-none bg-color-cell text-[16px] text-blue-800 outline-none",
						)}
					/>
					{inputIcon && (
						<img
							src={inputIcon}
							alt="url icon"
							className={clsx(
								"absolute left-[18px] top-1/2 w-[22px] -translate-y-1/2",
							)}
						/>
					)}

					<button
						type="submit"
						disabled={
							(isWaitlist
								? emailErrors !== ""
								: text.trim().length === 0 ||
								  !isUrl(text)) || isLoading
						}
						className={clsx(
							"h-[42px] max-w-[42px] rounded-[8px] disabled:pointer-events-none",
							"flex items-center justify-center tablet:min-w-[162px]",
							"min-w-[42px] bg-primary-500 disabled:opacity-40 tablet:max-w-[162px]",
							"select-none self-end transition-colors hover:bg-primary-600",
						)}>
						<div className="h-4 w-4 tablet:hidden">
							<SendIcon />
						</div>
						<span className="hidden text-[16px] font-600 text-color-white tablet:block">
							{button_text}
						</span>
					</button>
				</form>
			</section>
			<SubscriptionPopup
				isOpen={subscriptionPopupOpen}
				close={() => setSubscriptionPopupOpen(false)}
				location={router.asPath}
			/>
		</>
	)
}

const SendIcon = () => {
	return (
		<svg
			width="16"
			height="16"
			viewBox="0 0 16 16"
			fill="none"
			xmlns="http://www.w3.org/2000/svg">
			<path
				d="M10.5703 5.41407L6.7944 9.21516M6.7944 9.21516L2.37376 6.49431C1.79451 6.13772 1.91191 5.25798 2.56447 5.06858L13.0015 2.03162C13.595 1.85985 14.1438 2.4163 13.966 3.01259L10.8693 13.4388C10.6754 14.0913 9.80549 14.2043 9.45392 13.6217L6.7944 9.21516Z"
				stroke="white"
				strokeWidth="1.8"
				strokeLinecap="round"
				strokeLinejoin="round"
			/>
		</svg>
	)
}

export function GetHeader(props: {
	tag: TagsType
	title: string
	title_attr: TitleAttr
	children?: JSX.Element
	textStart?: boolean
}) {
	const { tag, title, children, title_attr, textStart } =
		props

	const { resolvedTheme } = useTheme()

	let formattedTitle: string | (string | JSX.Element)[] =
		title

	let lastText = ""
	if (title_attr) {
		formattedTitle = []
		let index = 0

		for (let i = 0; i < title.length; i++) {
			const isColored = title_attr.some(
				({ start, length }) => {
					return i >= start && i < start + length
				},
			)

			const attr = title_attr.find(({ start, length }) => {
				return i >= start && i < start + length
			})

			let color = "var(--color-primary-500)"

			if (
				attr &&
				resolvedTheme === "dark" &&
				attr.dark_color
			) {
				color = attr.dark_color
			} else if (attr && attr.color) {
				color = attr.color
			}

			if (isColored) {
				if (lastText) {
					formattedTitle.push(
						<span key={i}>{lastText}</span>,
					)
					lastText = ""
				}
				formattedTitle.push(
					<span
						key={index}
						style={{ color }}
						className="text-[24px] font-800 leading-tight tablet:text-[32px] desktop:text-[64px]">
						{title[i]}
					</span>,
				)
			} else {
				lastText += title[i]
			}

			index++
		}

		formattedTitle.push(
			<span key={title.length}>{lastText}</span>,
		)
	}

	switch (tag) {
		case "h1":
			return (
				<h1
					className={clsx(
						"whitespace-pre-line text-[32px] font-800 leading-[1.1] tablet:text-[32px] desktop:text-[64px]",
						textStart
							? "self-center text-center tablet:self-start tablet:text-start"
							: "self-center text-center",
					)}>
					{formattedTitle}
					{children}
				</h1>
			)
		case "h2":
			return (
				<h2
					className={clsx(
						"whitespace-pre-line text-[28px] font-800 leading-[1.1] tablet:text-[32px] desktop:text-[64px]",
						textStart
							? "self-center text-center tablet:self-start tablet:text-start"
							: "self-center text-center",
					)}>
					{formattedTitle}
					{children}
				</h2>
			)
		case "h3":
			return (
				<h3
					className={clsx(
						"whitespace-pre-line text-[24px] font-800 leading-[1.1] tablet:text-[32px] desktop:text-[52px]",
						textStart
							? "self-center text-center tablet:self-start tablet:text-start"
							: "self-center text-center",
					)}>
					{formattedTitle}
					{children}
				</h3>
			)
		case "h4":
			return (
				<h4
					className={clsx(
						"whitespace-pre-line text-[24px] font-800 leading-[1.1] tablet:text-[32px] desktop:text-[64px]",
						textStart
							? "self-center text-center tablet:self-start tablet:text-start"
							: "self-center text-center",
					)}>
					{formattedTitle}
					{children}
				</h4>
			)
	}
}
