import { CSSProperties } from "react"
import { z } from "zod"

const tagSchema = z.enum(["h1", "h2", "h3", "h4"])

export const textSchema = z.object({
	component: z.literal("text"),
	title: z.string(),
	padding_top: z.number().optional().default(0),
	padding_bottom: z.number().optional().default(0),
	tag: tagSchema.default("h2"),
})

type TextProps = z.infer<typeof textSchema>

export function Text(props: TextProps) {
	const { title, padding_bottom, padding_top, tag } = props

	switch (tag) {
		case "h1":
			return (
				<h1
					style={
						{
							"--padding-top": padding_top + "px",
							"--padding-bottom": padding_bottom + "px",
						} as CSSProperties
					}
					className="self-center whitespace-pre-line px-4 py-[16px] text-center text-[30px] font-800 leading-tight tablet:px-0 tablet:py-[40px] tablet:text-[32px] desktop:max-w-[1000px] desktop:pb-[var(--padding-bottom)] desktop:pt-[var(--padding-top)] desktop:text-[64px]">
					{title}
				</h1>
			)
		case "h2":
			return (
				<h2
					style={
						{
							"--padding-top": padding_top + "px",
							"--padding-bottom": padding_bottom + "px",
						} as CSSProperties
					}
					className="self-center whitespace-pre-line px-4 py-[16px] text-center text-[30px] font-800 leading-tight tablet:px-0 tablet:py-[40px] tablet:text-[32px] desktop:max-w-[1000px] desktop:pb-[var(--padding-bottom)] desktop:pt-[var(--padding-top)] desktop:text-[64px]">
					{title}
				</h2>
			)
		case "h3":
			return (
				<h3
					style={
						{
							"--padding-top": padding_top + "px",
							"--padding-bottom": padding_bottom + "px",
						} as CSSProperties
					}
					className="self-center whitespace-pre-line px-4 py-[16px] text-center text-[24px] font-800 leading-tight tablet:px-0 tablet:py-[40px] tablet:text-[32px] desktop:max-w-[1000px] desktop:pb-[var(--padding-bottom)] desktop:pt-[var(--padding-top)] desktop:text-[64px]">
					{title}
				</h3>
			)
		case "h4":
			return (
				<h4
					style={
						{
							"--padding-top": padding_top + "px",
							"--padding-bottom": padding_bottom + "px",
						} as CSSProperties
					}
					className="self-center whitespace-pre-line px-4 py-[16px] text-center text-[24px] font-800 leading-tight tablet:px-0 tablet:py-[40px] tablet:text-[32px] desktop:max-w-[1000px] desktop:pb-[var(--padding-bottom)] desktop:pt-[var(--padding-top)] desktop:text-[64px]">
					{title}
				</h4>
			)
	}
}
