import Image from "@/comps/image"
import Text from "@/comps/text"
import { assetUrl } from "@/utils/cdn"
import { FlexBox } from "react-styled-flex"
import styled from "styled-components"

const qrCodeImage = assetUrl("/home/qrcode.webp")

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 9px;
  margin: 9px;
  gap: 9px;

  background-color: var(--color-000);
  border-radius: 12px;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.13);
  text-align: center;
`
const QR = () => {
  return (
    <Container>
      <Image src={qrCodeImage} width={450} height={450} />
      <FlexBox
        width="140px"
        marginBottom={9}
        style={{ lineHeight: "16px" }}>
        <Text
          content="txt_download_qr"
          size="14"
          weight="500"
          color="700"
        />
      </FlexBox>
    </Container>
  )
}

export default QR
